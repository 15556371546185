import React , {useEffect} from "react";
import {Carousel , initTE} from "tw-elements"
// import Logo1 from "../img/Logo1.png";
import { RxDoubleArrowDown } from "react-icons/rx";

function CarouselHome (){

    function scrollSec(){
        const home1 = document.getElementById('Home1');
        home1?.scrollIntoView({
            behavior:"smooth"
        });
    };

    useEffect (() => {
        initTE({Carousel});
    },[]);
    
    return (
        <>
            <div id="CarouselHome" className="relative flex shadow-lg" data-te-carousel-init data-te-ride="carousel">
                <div className="relative w-screen overflow-hidden after:clear-both after:block after:content-['']">
                    <div className="relative float-left w-full !transform-none opacity-0 transition-opacity duration-[600ms] ease-in-out motion-reduce:transition-none" data-te-carousel-fade data-te-carousel-item data-te-carousel-active>
                        <div className="w-screen h-screen bg-bg1 relative bg-center bg-no-repeat bg-cover">
                            <div className="bg-black py-5 flex flex-col items-center bg-opacity-40 h-screen w-screen justify-between tracking-wide text-white">
                                <div className=" flex flex-col h-screen justify-center">
                                    <div className=" !font-serif space-y-4 text-center ">
                                        <h2 className="text-3xl lg:text-4xl italic text-white/80">Welcome To</h2>
                                        <div className="flex space-x-4 tracking-wider">
                                            <h3 className="text-5xl font-header-font lg:text-8xl text-main-color">SVP</h3>
                                            <h3 className="text-5xl lg:text-8xl">Machine</h3>
                                        </div>
                                    </div>
                               </div>
                                <div className="p-4 flex justify-center items-center">
                                    <button onClick={() => scrollSec()} className="group ">
                                        <RxDoubleArrowDown className="ease-in-out delay-100 duration-150 group-hover:-translate-y-2 group-hover:text-main2 text-5xl" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relative float-left -mr-[100%] hidden w-full !transform-none opacity-0 transition-opacity duration-[600ms] ease-in-out motion-reduce:transition-none" data-te-carousel-fade data-te-carousel-item>
                        <div className="w-screen h-screen bg-bg2 relative bg-center bg-no-repeat bg-cover">
                            <div className="flex !font-serif bg-black items-center bg-opacity-40 h-screen w-full justify-center tracking-wide text-white">
                                <div className=" !font-serif space-y-8 text-center italic ">
                                    <h2 className="text-3xl lg:text-6xl text-white ">Top-Rated CNC Services in San Jose</h2>
                                    <button  onClick={() => {
                                            window.location.href = 'mailto:info@svpmachine.com';
                                        }}
                                        className= "bg-main-color hover:bg-gray-300/40 ease-in-out delay-150 duration-200 group cursor-pointer w-[250px] py-3 capitalize">
                                        <a className = "group-hover:text-white tracking-wider uppercase">Contact Us</a>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relative float-left -mr-[100%] hidden w-full !transform-none opacity-0 transition-opacity duration-[600ms] ease-in-out motion-reduce:transition-none" data-te-carousel-fade data-te-carousel-item>
                        <div className="w-screen h-screen bg-bg3 relative bg-center bg-no-repeat bg-cover ">
                            <div className="flex !font-serif bg-black items-center bg-opacity-40 h-screen w-full justify-center tracking-wide text-white">
                                <div className=" !font-serif space-y-8 text-center italic ">
                                    <h2 className="text-3xl lg:text-6xl text-white ">Exclusive CNC Machining Quality</h2>
                                    <button
                                        className= "bg-main-color hover:bg-gray-300/40 ease-in-out delay-150 duration-200 group cursor-pointer w-[250px] py-3 capitalize">
                                        <a href="/Services" className = "group-hover:text-white tracking-wider uppercase">View More</a>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="absolute bottom-0 left-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none" type="button" data-te-target="#CarouselHome" data-te-slide="prev">
                    <span className="inline-block h-8 w-8">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="h-6 w-6">
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M15.75 19.5L8.25 12l7.5-7.5" />
                        </svg>
                    </span>
                    <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Previous</span>
                </button>
                <button className="absolute bottom-0 right-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none" type="button" data-te-target="#CarouselHome" data-te-slide="next">
                    <span className="inline-block h-8 w-8">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="h-6 w-6">
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                        </svg>
                    </span>
                    <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Next</span>
                </button>
            </div>
        </>
    );
};


export default CarouselHome;