import './App.css';
import React , {useState , useEffect} from 'react';
import Navbar from './Components/Navbar';
import Home from './Views/Home';
import { Route, Routes, Navigate , useLocation} from 'react-router-dom';
import FloatBtn from './Components/FloatBtn';
import Footer from './Components/Footer';
import About from './Views/About';
import Contact from './Views/Contact';
import Services from './Views/Services';
import { Fade } from 'react-awesome-reveal';

function App() {

  const [activeTab, setActiveTab] = useState("Home");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const isActive = (tabName) => {
      return activeTab === tabName ? " border-t-main-color border-t-2 p-2" : "";
  };

  const location = useLocation();

  useEffect(() => {

    const currentPath = location.pathname;

    switch (currentPath) {
      case "/About":
        setActiveTab("About");
        break;

      case "/Contact":
        setActiveTab("Contact");
        break;

      case "/Services":
        setActiveTab("Services");
        break;

      default:
        setActiveTab("Home");
        break;

    }
    
  }, [location]);

  return (
    <main className=' font-txt-font overflow-x-hidden'>
      <Navbar activeTab = {activeTab} setActiveTab = {setActiveTab} handleTabClick = {handleTabClick} isActive = {isActive} />
      
      <Fade>
        <Routes>
          <Route path = "/" element = {<Home activeTab = {activeTab} setActiveTab = {setActiveTab} handleTabClick = {handleTabClick} isActive = {isActive} />} />
          <Route path = "/About" element = {<About activeTab = {activeTab} setActiveTab = {setActiveTab} handleTabClick = {handleTabClick} isActive = {isActive} />} />
          <Route path='/Contact' element = {<Contact activeTab = {activeTab} setActiveTab = {setActiveTab} handleTabClick = {handleTabClick} isActive = {isActive}/>} />
          <Route path = "/Services" element = {<Services activeTab = {activeTab} setActiveTab = {setActiveTab} handleTabClick = {handleTabClick} isActive = {isActive}/>} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Fade>

      <FloatBtn />
      <Footer />
    </main>
  );
}

export default App;