import React from "react";
import CarouselHome from "../Components/CarouselHome";
import img1 from "../img/28.png";
import img2 from "../img/29.png";
import img3 from "../img/30.png";
import home3 from "../img/31.png";
import {Fade, Slide} from "react-awesome-reveal";
import cmm from "../img/img1.png";
import turn from "../img/img2.png";
import serimg from "../img/serimg.png";
import SwiperClient from "../Components/SwiperClient";
import info_img from "../img/info.png";
import info from "../img/info.pdf";

export default function Home(){

    const Data = [
        {
            id:1,
            name: 'Precision CMM Inspection',
            img: cmm,
            desc: 'SVPM ensures precision with state-of-the-art Coordinate Measuring Machines for every component.'
        },

        {
            id:2,
            name: 'Advanced CNC Turning',
            img: turn,
            desc: 'SVPM excels in CNC turning, delivering high-precision, intricate parts with exceptional quality and efficiency'
        }
    ]

    return(

        <main className="relative overflow-x-hidden">
            <section>
                <CarouselHome />
            </section>

            <section className=" bg-black" id="Home1">
                <div className=" pt-6 pb-8 lg:pt-24 lg:pb-24 space-y-4">
                    <div className="lg:p-4 p-2 mx-auto max-w-screen-xl">
                        <div className=" grid gap-8 lg:gap-12 place-items-center lg:grid-cols-4">
                            <Fade>
                                <div className=" flex flex-col lg:space-y-8">
                                    <div className="shadow-lg overflow-hidden">
                                        <img alt="da" className="ease-in-out opacity-65 hover:opacity-100 transition-all cursor-pointer duration-1000 hover:scale-125" src={img1} />
                                    </div>
                                    <div className=" pb-8"></div>
                                    <div className="shadow-lg overflow-hidden">
                                        <img alt="da" className="ml-4 ease-in-out opacity-65 hover:opacity-100 transition-all cursor-pointer duration-1000 hover:scale-125" src={img3} />
                                    </div>
                                </div>
                            </Fade>
                            <div className=" flex lg:col-span-2 space-y-4 lg:space-y-8 flex-col capitalize text-center">
                                <Slide duration={1000} direction="down">
                                    <span style={{fontFamily: 'Redressed'}} className=" text-2xl text-white/40">About us</span>
                                    <div className=" flex flex-col space-y-4">
                                        <span className=" text-4xl font-header-font text-gray-400">
                                            Embrace Precision Innovate with SVPM's.
                                        </span>
                                        <span style={{fontFamily: 'Calligraffitti'}} className="text-6xl text-main-color">
                                            Excellence
                                        </span>
                                    </div>
                                    <p className=" text-xl text-white font-txt-font">
                                        Founded in 1993 in Silicon Valley in San Jose California. SVPM Machine Provides Precision Mathining, Product Assembly , And Finishing. We Service The Medical , Solar , Semiconductor, Engineering , Aerospace, And Defense
                                    </p>
                                    <div className="">
                                        <button onClick={() => {
                                            window.open("/About")
                                        }} className="hover:shadow-lg font-txt-font text-white hover:-translate-y-2 hover:bg-main2 hover:text-white ease-in-out delay-100 duration-150 mt-5 md:text-xl border-2 border-main2 text-lg py-3 px-12 group cursor-pointer md:tracking-wide">
                                            <span className="">View More</span>
                                        </button>
                                    </div>
                                </Slide>
                            </div>
                            <Fade>
                                <div className=" flex flex-col lg:space-y-8">
                                    <div className="shadow-lg overflow-hidden">
                                        <img alt="da" className="ease-in-out opacity-65 hover:opacity-100 cursor-pointer transition-all duration-1000 hover:scale-125" src={img2} />
                                    </div>
                                    <div className=" pb-8"></div>
                                    <div className="shadow-lg overflow-hidden">
                                        <img alt="da" className="ml-4 ease-in-out opacity-65 hover:opacity-100 cursor-pointer transition-all duration-1000 hover:scale-125" src={home3} />
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>

            <section className=" bg-black">
                <div className=" p-5 pt-6 pb-8 lg:pt-12 lg:pb-24">
                    <div className="mx-auto space-y-6 lg:space-y-16 max-w-screen-xl">
                        <div className=" grid gap-8 lg:grid-cols-2">
                            <Fade>
                                <div className="flex flex-col space-y-8">
                                    <span style={{fontFamily: 'Redressed'}} className=" text-3xl text-white/40">Discover Excellence</span>
                                    <div className=" flex flex-col space-y-4">
                                        <span className=" text-4xl font-header-font text-gray-400">
                                            Expert Precision and Advanced Machining
                                        </span>
                                        <span style={{fontFamily: 'Calligraffitti'}} className="text-6xl text-main-color">
                                            Treatments
                                        </span>
                                    </div>
                                    <span className=" text-lg text-white font-txt-font">
                                        Choose SVPM for unparalleled accuracy and expertise. Experience cutting-edge machining services 
                                        in a state-of-the-art facility, 
                                        ensuring each project meets the highest standards of quality and precision
                                    </span>
                                    <div className=" font-txt-font text-white">
                                        <ul className=" space-y-8">
                                            <li className=" flex flex-col">
                                                <div className=" flex space-x-2 text-2xl">
                                                    <span style={{fontFamily: 'Calligraffitti'}} className="">01</span>
                                                    <span className="">
                                                        Expertise and Precision                                                    
                                                    </span>
                                                </div>
                                                <span className=" text-white/40 ml-8 text-lg font-thin">
                                                    Experience top-notch machining delivered by skilled technicians dedicated to perfection.                                                
                                                </span>
                                            </li>
                                            <li className=" flex flex-col">
                                                <div className=" flex space-x-2 text-2xl">
                                                    <span style={{fontFamily: 'Calligraffitti'}} className="">02</span>
                                                    <span className="">
                                                        Advanced Technology
                                                    </span>
                                                </div>
                                                <span className=" text-white/40 ml-8 text-lg font-thin">
                                                    Benefit from state-of-the-art equipment and innovative techniques ensuring superior quality.
                                                </span>
                                            </li>
                                            <li className=" flex flex-col">
                                                <div className=" flex space-x-2 text-2xl">
                                                    <span style={{fontFamily: 'Calligraffitti'}} className="">03</span>
                                                    <span className="">
                                                        Personalized Solutions
                                                    </span>
                                                </div>
                                                <span className=" text-white/40 ml-8 text-lg font-thin">
                                                    Enjoy customized machining solutions tailored to meet the specific needs of every project.                                                
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Fade>
                            <div className=" lg:hidden flex">
                                <img alt="da" src={serimg} />
                            </div>
                            <div className="hidden group cursor-pointer lg:block lg:bg-bgser lg:w-full lg:h-full lg:bg-center lg:bg-cover lg:bg-no-repeat">
                                <div className=" group-hover:bg-opacity-20 w-full h-full bg-black bg-opacity-50"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <Fade>
                    <div className="pb-8 lg:pb-24 bg-black">
                        <div className="">
                            <div className="mx-auto max-w-screen-xl lg:space-y-8 space-y-4 lg:p-6">
                                <div className=" grid gap-6 lg:gap-8 lg:grid-cols-2">
                                    <Fade>
                                        {Data.map((d) => (
                                            <div key={d.id} className="lg:p-4 p-2 lg:flex space-x-6 space-y-6">
                                                <div className="">
                                                    <img src={d.img} />
                                                </div>
                                                <div className=" font-txt-font space-y-3 lg:space-y-6 flex flex-col lg:justify-center lg:items-center text-center text-white">
                                                    <div className=" text-main-color text-2xl lg:text-3xl">
                                                        <h1>{d.name}</h1>
                                                    </div>
                                                    <div className=" text-lg">
                                                        <span>{d.desc}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </Fade>
                                </div>
                                <div className=" flex justify-center">
                                    <button onClick={() => {
                                        window.open('/Services')
                                    }} className="hover:shadow-lg font-txt-font text-white hover:-translate-y-2 hover:bg-main2 hover:text-white ease-in-out delay-100 duration-150 mt-5 md:text-xl border-2 border-main2 text-lg py-3 px-12 group cursor-pointer md:tracking-wide">
                                        <span className="">View More</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </section>

                                        
            <section className=" bg-bgcal bg-no-repeat w-full lg:h-[600px]">
                <div className="bg-black w-full lg:h-[600px] bg-opacity-55">
                    <div className="mx-auto space-y-6 text-white max-w-screen-lg">
                        <div className=" lg:flex justify-center items-center">
                            <div className=" lg:h-[600px] h-[500px] w-full px-6 lg:px-0 py-6">
                                <img onClick={() => {
                                    window.open(info);
                                }} className=" opacity-80 hover:opacity-100 cursor-pointer w-full h-full" src={info_img} />
                            </div>
                            <div className=" hidden lg:flex font-header-font space-y-8 flex-col">
                                <span className=" text-4xl text-center text-main-color">CAPABILITY STATEMENT</span>
                                <div className="text-center flex justify-center items-center">
                                    <div className=" w-4/5 text-xl">
                                        <span>
                                            SVPM provides precision machining, assembly, and finishing for medical, solar, semiconductor, 
                                            engineering, and high-tech industries. Superior quality and efficiency guaranteed
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className=" bg-white">
                <Slide duration={1000} direction="left">
                    <div className="p-5 pt-6 pb-8 lg:pt-12">
                        <div className="mx-auto space-y-6 lg:space-y-16 max-w-screen-lg">
                            <div className="">
                                <h1 style={{fontFamily: 'Redressed'}} className="text-4xl text-center">Our Clients</h1>
                            </div>
                            <div>
                                <SwiperClient />
                            </div>
                        </div>
                    </div>
                </Slide>
            </section>


        </main>
    )
}
