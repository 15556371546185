import {Swiper , SwiperSlide} from 'swiper/react';
import {Autoplay , Navigation} from "swiper/modules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faForward } from "@fortawesome/free-solid-svg-icons";
import 'swiper/css';
import 'swiper/css/navigation';
import React , {useRef} from 'react';
import img1 from "../img/Clients/1.jpg";
import img2 from "../img/Clients/2.jpg";
import img3 from "../img/Clients/3.jpg";
import img4 from "../img/Clients/4.png";
import img5 from "../img/Clients/5.jpg";
import img6 from "../img/Clients/6.jpg";
import img7 from "../img/Clients/7.jpg";
import img8 from "../img/Clients/8.png";
import img9 from "../img/Clients/9.png";


export default function SwiperClient() { 

    const swiperRef = useRef(null);

    const Data = [img1 , img2 , img3 , img4 , img5 , img6 , img7 , img8 , img9]

    return (
        <div className=' relative'>
            <Swiper
                onSwiper={(swiper) => swiperRef.current = swiper}
                loop = {true}
                breakpoints={{
                    340: {
                        slidesPerView:1,
                    },
                    700: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    }
                }}
                autoplay = {{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                // navigation = {true}
                modules={[Autoplay]}
            >
                {Data.map((s , index) => (
                    <SwiperSlide className='' key={index}>
                        <div className="p-4">
                            <div className="sticky cursor-pointer w-full h-[200px] lg:h-[150px] lg:w-[300px] block">
                                <img alt="#" className="rounded-lg h-full w-full " src={s} />
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )  
};